

@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap');*, ::before, ::after{--tw-border-spacing-x: 0;--tw-border-spacing-y: 0;--tw-translate-x: 0;--tw-translate-y: 0;--tw-rotate: 0;--tw-skew-x: 0;--tw-skew-y: 0;--tw-scale-x: 1;--tw-scale-y: 1;--tw-pan-x:  ;--tw-pan-y:  ;--tw-pinch-zoom:  ;--tw-scroll-snap-strictness: proximity;--tw-gradient-from-position:  ;--tw-gradient-via-position:  ;--tw-gradient-to-position:  ;--tw-ordinal:  ;--tw-slashed-zero:  ;--tw-numeric-figure:  ;--tw-numeric-spacing:  ;--tw-numeric-fraction:  ;--tw-ring-inset:  ;--tw-ring-offset-width: 0px;--tw-ring-offset-color: #fff;--tw-ring-color: rgb(59 130 246 / 0.5);--tw-ring-offset-shadow: 0 0 #0000;--tw-ring-shadow: 0 0 #0000;--tw-shadow: 0 0 #0000;--tw-shadow-colored: 0 0 #0000;--tw-blur:  ;--tw-brightness:  ;--tw-contrast:  ;--tw-grayscale:  ;--tw-hue-rotate:  ;--tw-invert:  ;--tw-saturate:  ;--tw-sepia:  ;--tw-drop-shadow:  ;--tw-backdrop-blur:  ;--tw-backdrop-brightness:  ;--tw-backdrop-contrast:  ;--tw-backdrop-grayscale:  ;--tw-backdrop-hue-rotate:  ;--tw-backdrop-invert:  ;--tw-backdrop-opacity:  ;--tw-backdrop-saturate:  ;--tw-backdrop-sepia:  
}::-webkit-backdrop{--tw-border-spacing-x: 0;--tw-border-spacing-y: 0;--tw-translate-x: 0;--tw-translate-y: 0;--tw-rotate: 0;--tw-skew-x: 0;--tw-skew-y: 0;--tw-scale-x: 1;--tw-scale-y: 1;--tw-pan-x:  ;--tw-pan-y:  ;--tw-pinch-zoom:  ;--tw-scroll-snap-strictness: proximity;--tw-gradient-from-position:  ;--tw-gradient-via-position:  ;--tw-gradient-to-position:  ;--tw-ordinal:  ;--tw-slashed-zero:  ;--tw-numeric-figure:  ;--tw-numeric-spacing:  ;--tw-numeric-fraction:  ;--tw-ring-inset:  ;--tw-ring-offset-width: 0px;--tw-ring-offset-color: #fff;--tw-ring-color: rgb(59 130 246 / 0.5);--tw-ring-offset-shadow: 0 0 #0000;--tw-ring-shadow: 0 0 #0000;--tw-shadow: 0 0 #0000;--tw-shadow-colored: 0 0 #0000;--tw-blur:  ;--tw-brightness:  ;--tw-contrast:  ;--tw-grayscale:  ;--tw-hue-rotate:  ;--tw-invert:  ;--tw-saturate:  ;--tw-sepia:  ;--tw-drop-shadow:  ;--tw-backdrop-blur:  ;--tw-backdrop-brightness:  ;--tw-backdrop-contrast:  ;--tw-backdrop-grayscale:  ;--tw-backdrop-hue-rotate:  ;--tw-backdrop-invert:  ;--tw-backdrop-opacity:  ;--tw-backdrop-saturate:  ;--tw-backdrop-sepia:  
}::backdrop{--tw-border-spacing-x: 0;--tw-border-spacing-y: 0;--tw-translate-x: 0;--tw-translate-y: 0;--tw-rotate: 0;--tw-skew-x: 0;--tw-skew-y: 0;--tw-scale-x: 1;--tw-scale-y: 1;--tw-pan-x:  ;--tw-pan-y:  ;--tw-pinch-zoom:  ;--tw-scroll-snap-strictness: proximity;--tw-gradient-from-position:  ;--tw-gradient-via-position:  ;--tw-gradient-to-position:  ;--tw-ordinal:  ;--tw-slashed-zero:  ;--tw-numeric-figure:  ;--tw-numeric-spacing:  ;--tw-numeric-fraction:  ;--tw-ring-inset:  ;--tw-ring-offset-width: 0px;--tw-ring-offset-color: #fff;--tw-ring-color: rgb(59 130 246 / 0.5);--tw-ring-offset-shadow: 0 0 #0000;--tw-ring-shadow: 0 0 #0000;--tw-shadow: 0 0 #0000;--tw-shadow-colored: 0 0 #0000;--tw-blur:  ;--tw-brightness:  ;--tw-contrast:  ;--tw-grayscale:  ;--tw-hue-rotate:  ;--tw-invert:  ;--tw-saturate:  ;--tw-sepia:  ;--tw-drop-shadow:  ;--tw-backdrop-blur:  ;--tw-backdrop-brightness:  ;--tw-backdrop-contrast:  ;--tw-backdrop-grayscale:  ;--tw-backdrop-hue-rotate:  ;--tw-backdrop-invert:  ;--tw-backdrop-opacity:  ;--tw-backdrop-saturate:  ;--tw-backdrop-sepia:  
}.container{width: 100%
}@media (min-width: 640px){.container{max-width: 640px
    }
}@media (min-width: 768px){.container{max-width: 768px
    }
}@media (min-width: 996px){.container{max-width: 996px
    }
}@media (min-width: 1024px){.container{max-width: 1024px
    }
}@media (min-width: 1280px){.container{max-width: 1280px
    }
}@media (min-width: 1536px){.container{max-width: 1536px
    }
}#root :is(.static){position: static
}#root :is(.fixed){position: fixed
}#root :is(.absolute){position: absolute
}#root :is(.relative){position: relative
}#root :is(.right-0){right: 0px
}#root :is(.mx-6){margin-left: 1.5rem;margin-right: 1.5rem
}#root :is(.mx-auto){margin-left: auto;margin-right: auto
}#root :is(.my-16){margin-top: 4rem;margin-bottom: 4rem
}#root :is(.my-3){margin-top: 0.75rem;margin-bottom: 0.75rem
}#root :is(.my-7){margin-top: 1.75rem;margin-bottom: 1.75rem
}#root :is(.mb-1){margin-bottom: 0.25rem
}#root :is(.mb-2){margin-bottom: 0.5rem
}#root :is(.mb-3){margin-bottom: 0.75rem
}#root :is(.mb-4){margin-bottom: 1rem
}#root :is(.mb-6){margin-bottom: 1.5rem
}#root :is(.mb-8){margin-bottom: 2rem
}#root :is(.ml-1){margin-left: 0.25rem
}#root :is(.ml-2){margin-left: 0.5rem
}#root :is(.ml-3){margin-left: 0.75rem
}#root :is(.mr-1){margin-right: 0.25rem
}#root :is(.mt-10){margin-top: 2.5rem
}#root :is(.mt-12){margin-top: 3rem
}#root :is(.mt-2){margin-top: 0.5rem
}#root :is(.mt-3){margin-top: 0.75rem
}#root :is(.mt-5){margin-top: 1.25rem
}#root :is(.mt-\[0\.3rem\]){margin-top: 0.3rem
}#root :is(.block){display: block
}#root :is(.flex){display: flex
}#root :is(.contents){display: contents
}#root :is(.hidden){display: none
}#root :is(.h-12){height: 3rem
}#root :is(.h-14){height: 3.5rem
}#root :is(.h-2){height: 0.5rem
}#root :is(.h-20){height: 5rem
}#root :is(.h-25){height: 104px
}#root :is(.h-5){height: 1.25rem
}#root :is(.h-6){height: 1.5rem
}#root :is(.h-98){height: 460px
}#root :is(.h-\[100vh\]){height: 100vh
}#root :is(.h-\[80vh\]){height: 80vh
}#root :is(.h-full){height: 100%
}#root :is(.min-h-120){min-height: 542px
}#root :is(.min-h-140){min-height: 633px
}#root :is(.min-h-46){min-height: 181px
}#root :is(.min-h-54){min-height: 216px
}#root :is(.min-h-80){min-height: 300px
}#root :is(.min-h-82){min-height: 325px
}#root :is(.min-h-90){min-height: 354px
}#root :is(.w-105){width: 472px
}#root :is(.w-130){width: 49.313rem
}#root :is(.w-54){width: 218px
}#root :is(.w-6){width: 1.5rem
}#root :is(.w-66){width: 260px
}#root :is(.w-80){width: 340px
}#root :is(.w-\[100vw\]){width: 100vw
}#root :is(.w-\[1px\]){width: 1px
}#root :is(.w-\[2px\]){width: 2px
}#root :is(.w-\[80\%\]){width: 80%
}#root :is(.w-full){width: 100%
}#root :is(.min-w-57){min-width: 229px
}#root :is(.min-w-80){min-width: 20rem
}#root :is(.max-w-100){max-width: 442px
}#root :is(.max-w-120){max-width: 518px
}#root :is(.max-w-150){max-width: 828px
}#root :is(.max-w-160){max-width: 995px
}#root :is(.max-w-60){max-width: 287px
}#root :is(.flex-1){flex: 1 1 0%
}#root :is(.transform){-webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}#root :is(.cursor-pointer){cursor: pointer
}#root :is(.flex-row){flex-direction: row
}#root :is(.flex-col){flex-direction: column
}#root :is(.flex-wrap){flex-wrap: wrap
}#root :is(.flex-wrap-reverse){flex-wrap: wrap-reverse
}#root :is(.items-end){align-items: flex-end
}#root :is(.items-center){align-items: center
}#root :is(.justify-end){justify-content: flex-end
}#root :is(.justify-center){justify-content: center
}#root :is(.justify-between){justify-content: space-between
}#root :is(.justify-around){justify-content: space-around
}#root :is(.gap-3){gap: 0.75rem
}#root :is(.gap-4){gap: 1rem
}#root :is(.gap-6){gap: 1.5rem
}#root :is(.overflow-auto){overflow: auto
}#root :is(.truncate){overflow: hidden;text-overflow: ellipsis;white-space: nowrap
}#root :is(.break-all){word-break: break-all
}#root :is(.rounded-lg){border-radius: 0.5rem
}#root :is(.rounded-md){border-radius: 0.375rem
}#root :is(.border){border-width: 1px
}#root :is(.border-none){border-style: none
}#root :is(.border-legacy-denim-light){--tw-border-opacity: 1;border-color: rgb(205 215 229 / var(--tw-border-opacity))
}#root :is(.bg-bg-100){--tw-bg-opacity: 1;background-color: rgb(246 246 250 / var(--tw-bg-opacity))
}#root :is(.bg-brand-100){--tw-bg-opacity: 1;background-color: rgb(224 13 0 / var(--tw-bg-opacity))
}#root :is(.bg-brand-300){--tw-bg-opacity: 1;background-color: rgb(211 34 42 / var(--tw-bg-opacity))
}#root :is(.bg-dark-10){--tw-bg-opacity: 1;background-color: rgb(215 215 215 / var(--tw-bg-opacity))
}#root :is(.bg-dark-500){--tw-bg-opacity: 1;background-color: rgb(40 46 55 / var(--tw-bg-opacity))
}#root :is(.bg-lightgrey-100){--tw-bg-opacity: 1;background-color: rgb(244 246 248 / var(--tw-bg-opacity))
}#root :is(.bg-orange-50){--tw-bg-opacity: 1;background-color: rgb(255 247 237 / var(--tw-bg-opacity))
}#root :is(.bg-sea-50){--tw-bg-opacity: 1;background-color: rgb(245 248 249 / var(--tw-bg-opacity))
}#root :is(.bg-sea-600){--tw-bg-opacity: 1;background-color: rgb(69 93 130 / var(--tw-bg-opacity))
}#root :is(.bg-transparent){background-color: transparent
}#root :is(.bg-white){--tw-bg-opacity: 1;background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}#root :is(.bg-cover){background-size: cover
}#root :is(.p-16){padding: 4rem
}#root :is(.p-2){padding: 0.5rem
}#root :is(.p-3){padding: 0.75rem
}#root :is(.p-6){padding: 1.5rem
}#root :is(.p-8){padding: 2rem
}#root :is(.px-10){padding-left: 2.5rem;padding-right: 2.5rem
}#root :is(.px-2){padding-left: 0.5rem;padding-right: 0.5rem
}#root :is(.px-4){padding-left: 1rem;padding-right: 1rem
}#root :is(.py-10){padding-top: 2.5rem;padding-bottom: 2.5rem
}#root :is(.py-2){padding-top: 0.5rem;padding-bottom: 0.5rem
}#root :is(.py-20){padding-top: 5rem;padding-bottom: 5rem
}#root :is(.py-3){padding-top: 0.75rem;padding-bottom: 0.75rem
}#root :is(.py-4){padding-top: 1rem;padding-bottom: 1rem
}#root :is(.py-8){padding-top: 2rem;padding-bottom: 2rem
}#root :is(.pb-16){padding-bottom: 4rem
}#root :is(.pb-4){padding-bottom: 1rem
}#root :is(.pl-4){padding-left: 1rem
}#root :is(.pr-2){padding-right: 0.5rem
}#root :is(.pr-8){padding-right: 2rem
}#root :is(.pt-16){padding-top: 4rem
}#root :is(.text-center){text-align: center
}#root :is(.text-4xl){font-size: 2.25rem;line-height: 2.5rem
}#root :is(.text-5xl){font-size: 3rem;line-height: 1
}#root :is(.text-7xl){font-size: 4.5rem;line-height: 1
}#root :is(.text-base){font-size: 1rem;line-height: 1.5rem
}#root :is(.text-lg){font-size: 1.125rem;line-height: 1.75rem
}#root :is(.text-sm){font-size: 0.875rem;line-height: 1.25rem
}#root :is(.text-xl){font-size: 1.25rem;line-height: 1.75rem
}#root :is(.text-xl-05){font-size: 1.40rem;line-height: 1.75rem
}#root :is(.text-xs){font-size: 0.75rem;line-height: 1rem
}#root :is(.font-bold){font-weight: 700
}#root :is(.font-semibold){font-weight: 600
}#root :is(.text-black){--tw-text-opacity: 1;color: rgb(0 0 0 / var(--tw-text-opacity))
}#root :is(.text-brand-100){--tw-text-opacity: 1;color: rgb(224 13 0 / var(--tw-text-opacity))
}#root :is(.text-brand-150){--tw-text-opacity: 1;color: rgb(196 33 37 / var(--tw-text-opacity))
}#root :is(.text-brand-300){--tw-text-opacity: 1;color: rgb(211 34 42 / var(--tw-text-opacity))
}#root :is(.text-dark-0){--tw-text-opacity: 1;color: rgb(0 0 0 / var(--tw-text-opacity))
}#root :is(.text-legacy-blackNear){--tw-text-opacity: 1;color: rgb(30 30 30 / var(--tw-text-opacity))
}#root :is(.text-text-200){--tw-text-opacity: 1;color: rgb(56 95 153 / var(--tw-text-opacity))
}#root :is(.text-text-300){--tw-text-opacity: 1;color: rgb(57 57 93 / var(--tw-text-opacity))
}#root :is(.text-text-500){--tw-text-opacity: 1;color: rgb(76 77 105 / var(--tw-text-opacity))
}#root :is(.text-text-800){--tw-text-opacity: 1;color: rgb(13 12 56 / var(--tw-text-opacity))
}#root :is(.text-text-900){--tw-text-opacity: 1;color: rgb(30 30 30 / var(--tw-text-opacity))
}#root :is(.text-white){--tw-text-opacity: 1;color: rgb(255 255 255 / var(--tw-text-opacity))
}#root :is(.underline){text-decoration-line: underline
}#root :is(.no-underline){text-decoration-line: none
}#root :is(.underline-offset-4){text-underline-offset: 4px
}#root :is(.shadow){--tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);--tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}#root :is(.shadow-basic){--tw-shadow: 0px 6px 8px 0px #0000001F, 0px 0px 4px 0px #00000029;--tw-shadow-colored: 0px 6px 8px 0px var(--tw-shadow-color), 0px 0px 4px 0px var(--tw-shadow-color);box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}#root :is(.blur){--tw-blur: blur(8px);-webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}#root :is(.grayscale){--tw-grayscale: grayscale(100%);-webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}#root :is(.invert){--tw-invert: invert(100%);-webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}#root :is(.sepia){--tw-sepia: sepia(100%);-webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}#root :is(.filter){-webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}#root :is(.backdrop-filter){-webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}#root :is(.transition-transform){transition-property: -webkit-transform;transition-property: transform;transition-property: transform, -webkit-transform;transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);transition-duration: 150ms
}#root :is(.group:hover .group-hover\:translate-x-2){--tw-translate-x: 0.5rem;-webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}@media (min-width: 640px){#root :is(.sm\:py-10){padding-top: 2.5rem;padding-bottom: 2.5rem
    }
}@media (min-width: 768px){#root :is(.md\:py-16){padding-top: 4rem;padding-bottom: 4rem
    }
}
